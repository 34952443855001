import React, { useEffect, useState } from "react";
import { TakeHomeDialog } from "../../primitives/DialogContent";

export const InitErrorModal = ({ notification }) => {
  const [showModal, setShowModal] = useState(false);
  const [info, setInfo] = useState({ title: "Init Error", description: "" });

  useEffect(() => {
    const data = notification?.data;
    if (!data || data.action !== "INIT") {
      return;
    }
    let description;
    let title;
    if (data.description.includes("role is invalid")) {
      description =
        "This role does not exist for the given room. Try again with a valid role.";
      title = "Invalid Role";
    } else if (data.description.includes("room is not active")) {
      title = "Room is disabled";
      description =
        "This room is disabled and cannot be joined. To enable the room, use the 100ms dashboard or the API.";
    } else if (data.description.includes("signature is invalid")) {
      description = "The meeting link is invalid. Try again with a valid link.";
      title = "Invalid meeting link";
    } else if (data.description.includes("token is expired")) {
      description =
        "You can no longer joing the meeting using this link as the meeting has ended.";
      title = "Meeting has ended";
    } else if (data.description.includes("token is not valid yet")) {
      description =
        "Meeting link has not started yet. You can join 5 mins prior to the meeting.";
      title = "Meeting not yet started.";
    } else {
      description = data.description;
      title = "Meeting Link not valid";
    }
    setInfo({ title, description });
    setShowModal(true);
  }, [notification]);

  return (
    <TakeHomeDialog
      open={showModal}
      onOpenChange={setShowModal}
      title={info.title}
      body={info.description}
    />
  );
};
